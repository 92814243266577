






































import { getCreditList, getPointInfo } from '@/api/credit';
import { ICreditInfo } from '@/api/type/base';
import { MerchantModule } from '@/store/modules/Merchant';
import dayjs from 'dayjs';
import { Vue, Component } from 'vue-property-decorator';
import BaseList from '@/components/Base/BaseList/index.vue';
import CreditListItem from './comm/CreditListItem.vue';
@Component({
  name: '',
  components: { BaseList, CreditListItem }
})
export default class extends Vue {
  pointInfo = {} as ICreditInfo;
  async getPointInfo() {
    const { payload } = await getPointInfo(MerchantModule.payPointId);
    this.pointInfo = payload;
  }

  get api() {
    return getCreditList;
  }

  get param() {
    const curTime = dayjs();
    return {
      pointId: MerchantModule.payPointId,
      startDate: curTime.subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss'),
      endDate: curTime.format('YYYY-MM-DD HH:mm:ss')
    };
  }

  created() {
    this.getPointInfo();
  }
}
